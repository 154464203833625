*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    background-color: #000000;
    min-height: 1800vh;

    height: 100%;
    /* position: fixed; */
    overflow-y: scroll;
    /* -webkit-overflow-scrolling: touch; */
}

#container 
{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction:column;
    align-items:center;
    justify-content: center;
}

#loading
{
    width: 100%;
    height: 100%;
    flex-direction:row;
    align-items: center;
    justify-content: center;
    display:flex; 
    z-index: 2;
    color: #f0f8ff;
}

#loading-flex
{
    width: 100%;
    height: 100%;
    flex-direction:column;
    align-items:center;
    justify-content: center;
    display:flex; 
    z-index: 2;
    color: #f0f8ff;
}

#container2
{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction:column;
    align-items:center;
    justify-content: center;
}

#loading2
{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    width: 100%;
    height: 100%;
    flex-direction:row;
    align-items:center;
    justify-content: center;
    display:flex; 
    z-index: 2;
    color: #f0f8ff;
}

#loading-flex2
{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    width: 100%;
    height: 100%;
    flex-direction:column;
    align-items:center;
    justify-content: center;
    display:flex; 
    z-index: 2;
    color: #f0f8ff;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

.page
{
    display: flex;
    align-items: center;
    z-index: 10;
    min-height: 100vh;
    position: relative;
    color: rgba(255, 255, 255, 0);
}

.score
{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-align: end;
    z-index: 10;
    min-height: 100vh;
    position: fixed; top:2%; right:2%;
    color: #f0f8ff;
    font-size: medium;
    max-width: 20vw;
    opacity: 1;
}

.click-to-play
{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: medium;
    display: none;
    position: fixed;
}

.or-scroll
{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: medium;
    display: none;
    position: fixed;
}

.new-high-score
{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    z-index: 10;
    min-height: 100vh;
    position: fixed; top:2%; left: 40%; right: 40%;
    color: #f0f8ff;
    text-align: center;
    font-size: medium;
    opacity: 1;
}

.hi-score
{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    z-index: 10;
    min-height: 100vh;
    position: fixed; top:2%; left:2%;
    color: #f0f8ff;
    font-size: medium;
    max-width: 20vw;
    opacity: 1;
}

#loadingText
{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: medium;
    opacity: 1;
}

/* .touch
{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    z-index: 10;
    min-height: 200vh;
    position: fixed; top:2%; left:2%;
    color: grey;
} */

header {
    display: inherit;
    z-index: 100;
    background: rgba(0, 0, 0, 0);
    height: 15vh;
    position: fixed;
    bottom: -3.75rem;
    width: 100%;
	transition: bottom 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    transition-property: bottom;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-delay: initial;
	text-align: center;
    opacity: 1;
    align-items: center;
    justify-content: center;
    justify-items: center;
    /* color: rgba(0, 0, 0, 0); */
    /* border-top: #f0f8ff;
    border-radius: 100%; */
}

.nav-up {
    bottom: 0;
}


#bottom-container 
{
    display: flex;
    flex-direction:row;
    align-items:center;
    justify-content: space-between;
}

#bottom-item
{
    z-index: 2;
    color: #f0f8ff;
}

.item
{
    justify-content: space-between;
    align-items: center;
}

#bottom-container #heavy-bucket-games
{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: small;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 5px;
    margin-right: 5px;
}

#socials .apple
{
    flex-direction: column;
    position: fixed;
    display: flex;
    z-index: 10;
    bottom: 4.8vh;
    right: 11vw;
    opacity: 1;
}

#socials .twitter
{
    flex-direction: column;
    position: fixed;
    display: flex;
    z-index: 10;
    bottom: 4.8vh;
    right: 3vw;
    opacity: 1;
}

.button-container
{
    flex-direction: column;
    position: fixed;
    display: flex;
    z-index: 10;
    bottom: 0%;
    left: 2%;
}

#mute-button
{
    cursor: pointer;
}

#mute-button img
{
    opacity: 1;
}

#games-text
{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    z-index: 10;
    position: fixed; top:50%;
    color: #f0f8ff;
    font-size: xx-large;
    opacity: 0;
}

